import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accordion";

const AccordionWrap = () => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            <AccordionItem id="one">
                <AccordionTitle id="one">
                    Kedim/Köpeğim neden sürekli kaşınıyor?
                </AccordionTitle>
                <AccordionContent id="one">
                    Kaşıntı genellikle birçok farklı nedenden kaynaklanabilir,
                    bunlar arasında parazitler (pul, pire, akarlar), alerjiler,
                    mantar enfeksiyonları veya cilt hastalıkları bulunur.
                    Veterineriniz, hayvanınızın durumunu inceleyerek ve
                    gerekirse testler yaparak doğru teşhisi koyabilir ve uygun
                    tedaviyi önerebilir.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="two">
                <AccordionTitle id="two">
                    Kedim/Köpeğim neden iştahsız?
                </AccordionTitle>
                <AccordionContent id="two">
                    İştahsızlık, birçok farklı sağlık sorununun belirtisi
                    olabilir. Bunlar arasında sindirim sistemi sorunları, diş
                    ağrısı, enfeksiyonlar, organ hastalıkları veya stres
                    bulunur. Veterineriniz, hayvanınızın genel sağlık durumunu
                    değerlendirerek ve gerekirse testler yaparak temel nedeni
                    belirleyip uygun tedaviyi önerebilir.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="three">
                <AccordionTitle id="three">
                    Kedim/Köpeğim neden sık sık kusuyor?
                </AccordionTitle>
                <AccordionContent id="three">
                    Kusma, birçok farklı nedenden kaynaklanabilir. Yeme
                    alışkanlıkları, sindirim sistemi sorunları, zehirlenme,
                    parazitler veya iç organ hastalıkları gibi durumlar kusmaya
                    neden olabilir. Veterineriniz, hayvanınızın tıbbi geçmişini
                    değerlendirerek ve gerekirse testler yaparak temel nedeni
                    belirleyebilir ve uygun tedaviyi önerebilir.
                </AccordionContent>
            </AccordionItem>
            <AccordionItem id="four">
                <AccordionTitle id="four">
                    Kedim/Köpeğim neden tuvaletini yapamıyor?
                </AccordionTitle>
                <AccordionContent id="four">
                    Tuvalet yapma sorunu, altta yatan birçok farklı sağlık
                    sorunundan kaynaklanabilir. Bunlar arasında idrar yolu
                    enfeksiyonları, bağırsak tıkanıklıkları, kabızlık, idrar
                    taşı, obezite veya sinirsel sorunlar bulunabilir.
                    Veterineriniz, hayvanınızın semptomlarını değerlendirerek ve
                    gerekirse testler yaparak temel nedeni belirleyebilir ve
                    uygun tedaviyi önerebilir.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionWrap;

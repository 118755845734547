import React, { Fragment, useRef } from "react";
import emailjs, { sendForm } from "emailjs-com";
import { useForm } from "react-hook-form";

const AppointmentForm = () => {
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
    });

    const form = useRef();

    const onSubmit = (data) => {
        sendForm(
            "service_ki922ha", // replace with your EmailJS service ID
            "template_p9835yb", // replace with your EmailJS template ID
            form.current,
            "qfH4J_cl-fP_6Pmgz" // replace with your EmailJS public key
        ).then(
            (result) => {
                console.log(result.text);
                alert("Mailiniz gönderildi!");
            },
            (error) => {
                console.log(error.text);
                alert("Beklenmeyen bir hata oluştu. Lütfen Tekrar deneyin.");
            }
        );
    };

    return (
        <Fragment>
            <form ref={form} onSubmit={handleSubmit(onSubmit)} method="POST">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                name="user_name"
                                placeholder="İsim"
                                ref={register({ required: "İsim gereklidir" })}
                            />
                            {errors.user_name && (
                                <p>{errors.user_name.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="email"
                                name="user_email"
                                placeholder="Email"
                                ref={register({
                                    required: "Email gereklidir.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Hatalı Email",
                                    },
                                })}
                            />
                            {errors.user_email && (
                                <p>{errors.user_email.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="tel"
                                name="user_phone"
                                placeholder="Telefon numarası"
                                ref={register({
                                    required: "Telefon numarası gereklidir.",
                                })}
                            />
                            {errors.user_phone && (
                                <p>{errors.user_phone.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <textarea
                                name="message"
                                rows="7"
                                placeholder="Mesajınız"
                                ref={register({
                                    required: "Message is required",
                                })}
                            ></textarea>
                            {errors.message && <p>{errors.message.message}</p>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-0">
                            <button className="btn btn-light" type="submit">
                                Bize Ulaşın
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default AppointmentForm;

import React from "react";
import "./index.css";

const ThankYouMessage = () => {
    return (
        <div className="thank-you-message">
            <h2>Değerli Hasta Sahiplerimiz</h2>
            <p>
                Anka Veteriner Kliniği olarak 10. yılımıza ulaşmanın gururunu ve
                mutluluğunu yaşıyoruz. Bu özel dönüm noktasında, siz değerli
                hasta sahiplerimize yürekten teşekkür etmek istiyoruz. Bizleri
                tercih ettiğiniz, güveninizi ve sevginizi bizlerden
                esirgemediğiniz için minnettarız.
            </p>
            <p>
                Her adımımızda yanımızda olan sizler, sevgili dostlarınızla
                birlikte kliniğimizi sadece bir tedavi merkezi değil, aynı
                zamanda bir aile haline getirdiniz. Güzel anılar
                biriktirdiğimiz, zorlukları birlikte aştığımız bu on yıllık
                yolculuk, sizlerin desteği ve sevgisi sayesinde mümkün oldu.
            </p>
            <p>
                Bu yolculuk boyunca sevinçlerinizi, endişelerinizi ve
                umutlarınızı paylaştınız. Bizlere gösterdiğiniz güven ve
                sadakat, her gün daha da büyük bir özveri ile çalışmamız için en
                büyük motivasyon kaynağımız oldu.
            </p>
            <p>
                Gelecek yıllarda da aynı sevgi ve bağlılıkla, her zaman
                yanınızda olacağız. Bizi seçtiğiniz ve bu güzel yolculukta
                yanımızda olduğunuz için sonsuz teşekkürlerimizi sunarız.
            </p>
            <p>Sevgi ve Saygılarımızla,</p>
            <p>
                <strong>Anka Veteriner Kliniği Ekibi</strong>
            </p>
        </div>
    );
};

export default ThankYouMessage;

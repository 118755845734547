import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
    return (
        <div
            className="divider-area bgcolor-theme bg-img"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/img/shape/01.jpg)`,
            }}
        >
            <div className="container">
                <div className="row content-align-center">
                    <div className="col-lg-12">
                        <div
                            className="divider-content-area divider-content-style1"
                            data-aos="fade-up"
                            data-aos-duration="1200"
                        >
                            <div className="content-inner">
                                <h2>
                                    Sizin <span>için burdayız!</span>
                                </h2>
                                <p>
                                    Tüm acil durumlar ve daha fazlası için bizi
                                    arayınız!
                                </p>
                            </div>
                            <Link
                                to="/contact"
                                className="btn btn-theme btn-white"
                            >
                                Bize Ulaşın
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CallToAction;

import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${
                show ? "offcanvas-open" : ""
            }`}
        >
            <div className="inner">
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>Adres:</span> Cevat Paşa, Atatürk Cd. No:15
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        <li>
                            <NavLink exact to={process.env.PUBLIC_URL + "/"}>
                                <span className="menu-text">Ana Sayfa</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        exact
                                        to={process.env.PUBLIC_URL + "/"}
                                    >
                                        Ana Sayfa
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/hizmetler"}>
                                <span className="menu-text">Hizmetlerimiz</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/hizmetler"
                                        }
                                    >
                                        Hizmetler
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/hizmet-detaylari/1"
                                        }
                                    >
                                        Hizmet Detayları
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                <span className="menu-text">Makale & Blog</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/blog"}
                                    >
                                        Liste
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/blog-detaylari/1"
                                        }
                                    >
                                        Detaylar
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink
                                to={process.env.PUBLIC_URL + "/hakkimizda"}
                            >
                                Hakkımızda
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/iletisim"}>
                                İletişim
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="offcanvas-social my-4">
                    <ul>
                        <li>
                            <SocialIcon
                                path="https://wa.me/905352133417"
                                icon="icofont-whatsapp"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.facebook.com/canakkaleankaveteriner"
                                icon="icofont-facebook"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.instagram.com/ankaveterinerklinigi"
                                icon="icofont-instagram"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.tiktok.com/@ankaveterinerklinik"
                                icon="icofont-rss-feed"
                            />
                        </li>
                    </ul>
                </div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-clock-time"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                Çalışma Saatleri
                            </span>
                            <span className="media-heading">
                                Hafta İçi: 9.00 - 19.00 Sonu: 10.00 - 19.00{" "}
                            </span>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-ui-call"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">7/24 Hat</span>
                            <a
                                className="media-heading"
                                href="https://wa.me/905352133417"
                            >
                                +0535 213 34 17
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;

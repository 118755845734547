import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../CustomModal";
import { convertNewlinesToBreaks } from "../../utils";
import "./Resume.css";

const Resume = ({ data, isOpen, onClose }) => {
    return (
        <CustomModal isOpen={isOpen} onClose={onClose}>
            <div className="team-member">
                <div className="row">
                    <div className="col-md-3 mt-4 col-0">
                        <img
                            className="resume-image"
                            src={process.env.PUBLIC_URL + data.image}
                            alt="ekip üyeleri"
                        />
                    </div>
                    <div className="col-md-9 col-11">
                        <div className="content">
                            <div className="member-info">
                                <h4 className="name resume-name">
                                    {data.name}
                                </h4>
                                <p className="paragraph">
                                    {convertNewlinesToBreaks(data.excerpt)}
                                </p>
                                <p>{convertNewlinesToBreaks(data.resume)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

Resume.propTypes = {
    data: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Resume;

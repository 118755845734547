import React from "react";
import { Link } from "react-router-dom";
import AppointmentForm from "../appointment-form";
import SectionTitle from "../section-title";
import AppointmentFormContact from "../appointment-form-contact";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Merkez, <span>Çanakkale</span>
                </h2>
                <p>
                    Cevat Paşa, Atatürk Cd. No:15
                    <br />
                    Merkez/Çanakkale
                </p>
                <div className="contact-form">
                    <AppointmentFormContact />
                </div>
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>7/24 Hat Bize Ulaşın</h6>
                    <p>+90 535 213 34 17</p>
                </div>
                <div className="info-tem">
                    <h6>Şubelerimiz:</h6>
                    <p>
                        Güzelyalı Veteriner Kliniği <br />
                        Güzelyalı Mevkii, İzmir Cd. No:207 D:1, 17100 Çanakkale
                        Merkez/Çanakkale
                    </p>
                </div>
                <div className="info-tem mb-0">
                    <h6>Hizmet Saatleri:</h6>
                    <p>
                        Hafta içi: 9.00 - 19.00
                        <br />
                        Hafta sonu: 10.00 - 19.00
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;

import React from "react";
import "./Timeline.css";

const Timeline = () => {
    return (
        <div className="timeline-container">
            <div className="timeline">
                <div className="timeline-event left">
                    <div className="timeline-event-content">
                        <h3>2014 - Kliniğimizin Kuruluşu</h3>
                        <p>
                            Kliniğimiz ilk olarak kapılarını açtı ve
                            hastalarımızı kabul etmeye başladık.
                        </p>
                    </div>
                </div>
                <div className="timeline-event right">
                    <div className="timeline-event-content">
                        <h3>2016 - İlk Büyük Genişleme</h3>
                        <p>
                            Kliniğimizin hizmet yelpazesi genişletildi ve yeni
                            veterinerler ekibimize katıldı.
                        </p>
                    </div>
                </div>
                <div className="timeline-event left">
                    <div className="timeline-event-content">
                        <h3>2018 - Teknolojik Yatırımlar</h3>
                        <p>
                            Yeni tıbbi cihazlar ve teknolojiler kliniğimize
                            kazandırıldı.
                        </p>
                    </div>
                </div>
                <div className="timeline-event right">
                    <div className="timeline-event-content">
                        <h3>2020 - Yeni Hizmetler</h3>
                        <p>
                            Pet oteli ve mobil veteriner hizmetleri gibi yeni
                            hizmetler sunmaya başladık.
                        </p>
                    </div>
                </div>
                <div className="timeline-event left">
                    <div className="timeline-event-content">
                        <h3>2022 - Onur Ödülü</h3>
                        <p>
                            Yerel topluluk tarafından en iyi veteriner kliniği
                            ödülüne layık görüldük.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timeline;

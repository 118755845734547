import React from "react";
import PropTypes from "prop-types";
import { convertNewlinesToBreaks } from "../../utils";
import "./Team.css";

const Team = ({ data, onOpenModal }) => {
    return (
        <div className="team-member">
            <div className="thumb">
                <img
                    src={process.env.PUBLIC_URL + data.image}
                    alt="ekip üyeleri"
                />
            </div>
            <div className="content">
                <div className="member-info">
                    <h4 className="name">{data.name}</h4>
                    <p>{convertNewlinesToBreaks(data.excerpt)}</p>
                    <button
                        onClick={() => onOpenModal(data)}
                        className="btn-special"
                    >
                        Devamını Oku
                    </button>
                </div>
            </div>
        </div>
    );
};

Team.propTypes = {
    data: PropTypes.object.isRequired,
    onOpenModal: PropTypes.func.isRequired,
};

export default Team;

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import useLazyLoading from "../../hooks/useLazyLoading";

const LazyLoadVideo = ({ src, poster, type }) => {
    const videoRef = useRef();
    const isIntersecting = useLazyLoading(videoRef);
    const [isVideoVisible, setIsVideoVisible] = useState(true);

    const handleVideoEnd = () => {
        setIsVideoVisible(false);
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener("ended", handleVideoEnd);
        }
        return () => {
            if (videoElement) {
                videoElement.removeEventListener("ended", handleVideoEnd);
            }
        };
    }, []);

    return (
        isVideoVisible && (
            <video
                ref={videoRef}
                autoPlay
                muted
                poster={poster}
                preload="none"
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    objectFit: "cover",
                    opacity: isIntersecting ? 1 : 0,
                    transition: "opacity 0.5s",
                }}
            >
                {isIntersecting && <source src={src} type={type} />}
                <track
                    kind="captions"
                    src="path/to/captions.vtt"
                    label="English"
                />
                Your browser does not support the video tag.
            </video>
        )
    );
};

LazyLoadVideo.propTypes = {
    src: PropTypes.string.isRequired,
    poster: PropTypes.string,
    type: PropTypes.string,
};

LazyLoadVideo.defaultProps = {
    poster: "",
    type: "video/mp4",
};

export default LazyLoadVideo;

import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-9">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Logo
                                    classOption="footer-logo"
                                    image={`${process.env.PUBLIC_URL}/img/logo-dark.png`}
                                />
                                <p className="mb-4">
                                    Evcil dostlarımızın sağlığı ve mutluluğu
                                    bizim önceliğimizdir. Anka Veteriner
                                    Kliniği, uzman kadrosuyla, modern tıbbi
                                    tekniklerle donatılmış bir ortamda, evcil
                                    dostlarımızın ihtiyaçlarına özenle ve
                                    sevgiyle yaklaşır. Huzurlu bir ortamda,
                                    dostlarımızın sağlığı için buradayız.
                                </p>
                                <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        Cevat Paşa, Atatürk Cd. No:15,
                                        Merkez/Çanakkale
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto://hello@yourdomain.com">
                                            ---
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i className="icofont-ui-call"></i>
                                        <a href="https://wa.me/905352133417">
                                            +90 535 213 34 17
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Bültene Kayıt Olun!
                            </h4>
                            <div className="widget-newsletter">
                                <p>
                                    Makalelelerimizden ve yeniliklerimizden
                                    haberdar olmak için lütfen kayıt olun!
                                </p>
                                <Newsletter mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                            </div>
                            <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://www.facebook.com/canakkaleankaveteriner?locale=tr_TR/"
                                    icon="icofont-facebook"
                                />

                                <SocialIcon
                                    path="https://www.instagram.com/ankaveterinerklinigi/"
                                    icon="icofont-instagram"
                                />

                                <SocialIcon
                                    path="https://wa.me/+905352133417"
                                    icon="icofont-whatsapp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2024{" "}
                                    <span className="text-uppercase">
                                        Anka{" "}
                                    </span>
                                    Veteriner Kliniği{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import SocialIcon from "../../components/social-icon";
import React from "react";
import "./whatsapp-button.css";
const WhatsappButton = () => {
    const phoneNumber = "+905352133417";
    const message = "Randevu ve istekleriniz için.";
    const handleClick = () => {
        window.open(
            `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
            "_blank"
        );
    };
    return (
        <button className="whatsapp-button" onClick={handleClick}>
            <i className="icofont-brand-whatsapp"></i>
        </button>
    );
};
export default WhatsappButton;

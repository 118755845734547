import { Link } from "react-router-dom";
import SidebarTitle from "../sidebar-title";

const SidebarWorkingTime = () => {
    return (
        <div className="widget-item">
            <div className="widget-work-hours">
                <SidebarTitle classOption="title" title="Çalışma Saatleri" />
                <ul>
                    <li>
                        <span className="work-day">Pazartesi</span>
                        <span className="work-hours">9:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Salı</span>
                        <span className="work-hours">9:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Çarşamba</span>
                        <span className="work-hours">9:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Perşembe</span>
                        <span className="work-hours">9:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Cuma</span>
                        <span className="work-hours">9:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Cumartesi</span>
                        <span className="work-hours">10:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Pazar</span>
                        <span className="work-hours">10:00 - 19:00</span>
                    </li>
                    <li>
                        <span className="work-day">Acil 7/24</span>
                        <span className="work-hours">Arayabilirsiniz</span>
                    </li>
                </ul>
                <Link className="btn-theme btn-white" to="/iletisim">
                    Bize Ulaşın
                </Link>
            </div>
        </div>
    );
};

export default SidebarWorkingTime;

import React from "react";
import Layout from "../layouts/index.jsx";
import SEO from "../components/seo";
import Header from "../layouts/header";
import PageTitleContainer from "../containers/global/page-title";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import LazyLoadVideo from "../components/lazy-load-video/index.jsx";
import phoenix from "../assets/videos/phoenix.mp4";
import ThankYouMessage from "../components/thanks-message/index.jsx";
import Timeline from "../components/timeline/index.jsx";

const SpecialPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="10. Yıl | Anka Veteriner Kliniği" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/slider/ankason.webp"
                            subTitle=""
                            title="."
                        />
                        <LazyLoadVideo
                            src={phoenix}
                            poster="../public/img/logo.png" // Assuming you have a poster image in the public folder
                            type="video/mp4"
                        />
                        <ThankYouMessage />
                        <Timeline />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SpecialPage;

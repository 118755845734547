import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import HomeData from "../../../data/home.json";
import Team from "../../../components/team";
import SectionTitle from "../../../components/section-title";
import Resume from "../../../components/resume"; // Import the 'Resume' component

SwiperCore.use([Pagination, Autoplay]);
const TeamContainerAbout = () => {
    const [selectedTeamMember, setSelectedTeamMember] = useState(null);

    const handleOpenModal = (data) => {
        setSelectedTeamMember(data);
    };

    const handleCloseModal = () => {
        setSelectedTeamMember(null);
    };
    const swiperOption = {
        loop: true,
        speed: 600,
        spaceBetween: 30,
        slidesPerView: 3,
        pagination: { type: "fraction" },
        autoplay: { delay: 2500 },
        breakpoints: {
            1200: {
                slidesPerView: 3,
            },

            991: {
                slidesPerView: 2,
            },

            767: {
                slidesPerView: 2,
            },

            560: {
                slidesPerView: 2,
            },

            0: {
                slidesPerView: 1,
            },
        },
    };
    return (
        <div className="">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Ekibimizle Tanışın"
                            title="<span>Profesyonel </span> Ekip Arkadaşlarımız"
                        />
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-duration="1300"
                    >
                        <Swiper
                            className="team-slider-container"
                            {...swiperOption}
                        >
                            {HomeData[4].team &&
                                HomeData[4].team.map((single, key) => {
                                    return (
                                        <SwiperSlide key={key}>
                                            <Team
                                                key={key}
                                                data={single}
                                                onOpenModal={handleOpenModal}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
            {selectedTeamMember && (
                <Resume
                    data={selectedTeamMember}
                    isOpen={!!selectedTeamMember}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default TeamContainerAbout;

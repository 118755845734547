import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="Anka Veteriner Kliniği"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3049.5387516082988!2d26.413460999999998!3d40.152557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b1a9d37d8c1039%3A0x3b36f3f0b62e0c4d!2sCanakkale%20Anka%20Veterinary%20Clinic!5e0!3m2!1sen!2str!4v1714491194157!5m2!1sen!2str%22"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
